// This where we can apply global styles that will be applied broadly across all sales channels and environments.

// Any SASS or CSS written here will override the bootstrap scss if you specify "className" or greater in the css hierarchy, otherwise the bootstrap scss file imported at the bottom will win out.  

// If you want to specify styling for a single theme then modify the theme config object in /redux/themes.ts. This will make it available from both the redux store, via the regular old selector, and also the stlyed components theme provider as props.
// If you want to add a new attribute then be sure to update the Theme Type found in types.d.ts

// You can also use styled components to change styling on a component itself

//the $theme-colors variable allows us to add theme colors to bootstrap just like "primary", "secondary," "alert," etc. for now I think it is easier to add new theme-colors rather than overwrite the default ones, mostly because naming them ourselves will be less confusing when switching themes.


body {
    // margin: 0;
    font-family: -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

  $theme-colors: (
  "retail-primary": #981B1E,
  "hd-primary": #ED7124,
);

main {
  min-height: 80vh;
}

.disclaimer {
  font-size: 12px !important;
  line-height: 14px;
  font-style: italic;
  // font-weight: 300;
}

.btn-checkout {
  width: 100%;
}

// Journal 5.1.3
// Bootswatch

$theme: "HD" !default;

//
// Color system
//

$white:    #fafafa !default;
$offWhite: #f5f5f5 !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #aaa !default;
$gray-600: #777 !default;
$gray-700: #495057 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;
$black:    #000 !default;

$blue:    blue !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #f57a00 !default;
$orange:  #ED7124 !default;
$yellow:  #f5e625 !default;
$green:   #22b24c !default;
$teal:    #20c997 !default;
$cyan:    #369 !default;

$primary:       $orange !default;
$secondary:     $gray-500 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$min-contrast-ratio:   1.28 !default;

// Fonts

$headings-font-family:   "Segoe UI", "Roboto", "Arial Narrow Bold", sans-serif !default;
// $headings-font-family:   'Roboto', 'Helvetica', sans-serif !default;

$headings-font-weight:    700 !default;
$headings-line-height:    1.1 !default;

// Buttons

$input-btn-padding-x:         1rem !default;

// Navbar

$navbar-light-color:                rgba($black, .7) !default;
$navbar-light-hover-color:          $black !default;
$navbar-light-active-color:         $black !default;

// Pagination

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $primary !default;
$pagination-hover-border-color:     $primary !default;






// $web-font-path: "https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap" !default;
// @if $web-font-path {
//   @import url($web-font-path);
// }

// Navbar

.bg-dark {
  background-color: $black !important;
}

.bg-light {
  background-color: $white !important;
  color: $black;
  // border: .5px solid $black;

  &.navbar-fixed-top {
    border-width: 0 0 1px;
  }

  &.navbar-fixed-bottom {
    border-width: 1px 0 0;
  }
}

.navbar {
  font-size: 18px;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
}

.navbar-brand {
  padding-top: .5rem;
  font-size: inherit;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
}

// Buttons

.btn {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;

  &-secondary,
  &-warning {
    color: $white;
  }
}

// Navs

.pagination {
  a:hover {
    text-decoration: none;
  }
}


.border-success {
  border-color: #20c997 !important;
}

.bg-success {
  border-color: #20c997 !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: white;
}

.confirmationPage-Container {
  margin-top: 1.5rem;
  // font-family: 'Segoe UI' sans-serif;
  // font-weight: 'Segoe UI';
  // display: flex;
  // justify-content: left;
  // align-items: left;
  // height: inherit;
  // background-color: white;
}

.confirmationPage-Title {
  font-size: 2.5rem;
}

.confirmationPage-Text {
  font-size: 1.2rem;

}
.btn-return-home {

}

.modalHead {
  color: red;
}
.modalBody{

}

// .iFrameContainer{
//   padding-bottom: 1rem;
// }

.summaryIframeContainer {
    padding-bottom: 1rem;

}

.spinner-container {
  padding-top: 2rem;
}

.partialPayDisclaimer {
  font-size: small;
  line-height: 124%;
}
// .card {
//   position: relative;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   min-width: 0;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: border-box;
//   border: 1px solid rgba(0, 0, 0, 0.125);
//   border-radius: 0.5rem;
// }

// .card > hr {
//   margin-right: 0;
//   margin-left: 0;
// }

// .card > .list-group {
//   border-top: inherit;
//   border-bottom: inherit;
// }

// .card > .list-group:first-child {
//   border-top-width: 0;
//   border-top-left-radius: calc(0.25rem - 1px);
//   border-top-right-radius: calc(0.25rem - 1px);
// }

// .card > .list-group:last-child {
//   border-bottom-width: 0;
//   border-bottom-right-radius: calc(0.25rem - 1px);
//   border-bottom-left-radius: calc(0.25rem - 1px);
// }

// .card > .card-header + .list-group,
// .card > .list-group + .card-footer {
//   border-top: 0;
// }

// .card-body {
//   -ms-flex: 1 1 auto;
//   flex: 1 1 auto;
//   padding: 1rem 1rem;
// }

// .card-title {
//   margin-bottom: 0.5rem;
// }

// .card-subtitle {
//   margin-top: -0.25rem;
//   margin-bottom: 0;
// }

// .card-text:last-child {
//   margin-bottom: 0;
// }

// .card-link + .card-link {
//   margin-left: 1rem;
// }

// .card-header {
//   padding: 0.5rem 1rem;
//   margin-bottom: 0;
//   background-color: rgba(0, 0, 0, 0.03);
//   border-bottom: 1px solid rgba(0, 0, 0, 0.125);
// }

// .card-header:first-child {
//   border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
// }

// .card-footer {
//   padding: 0.5rem 1rem;
//   background-color: rgba(0, 0, 0, 0.03);
//   border-top: 1px solid rgba(0, 0, 0, 0.125);
// }

// .card-footer:last-child {
//   border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
// }

// .card-header-tabs {
//   margin-right: -0.5rem;
//   margin-bottom: -0.5rem;
//   margin-left: -0.5rem;
//   border-bottom: 0;
// }

// .card-header-pills {
//   margin-right: -0.5rem;
//   margin-left: -0.5rem;
// }

// .card-img-overlay {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   padding: 1rem;
//   border-radius: calc(0.25rem - 1px);
// }

// .card-img,
// .card-img-top,
// .card-img-bottom {
//   width: 100%;
// }

// .card-img,
// .card-img-top {
//   border-top-left-radius: calc(0.25rem - 1px);
//   border-top-right-radius: calc(0.25rem - 1px);
// }

// .card-img,
// .card-img-bottom {
//   border-bottom-right-radius: calc(0.25rem - 1px);
//   border-bottom-left-radius: calc(0.25rem - 1px);
// }

// .card-group > .card {
//   margin-bottom: 0.75rem;
// }

// @media (min-width: 576px) {
//   .card-group {
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-flow: row wrap;
//     flex-flow: row wrap;
//   }
//   .card-group > .card {
//     -ms-flex: 1 0 0%;
//     flex: 1 0 0%;
//     margin-bottom: 0;
//   }
//   .card-group > .card + .card {
//     margin-left: 0;
//     border-left: 0;
//   }
//   .card-group > .card:not(:last-child) {
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//   }
//   .card-group > .card:not(:last-child) .card-img-top,
//   .card-group > .card:not(:last-child) .card-header {
//     border-top-right-radius: 0;
//   }
//   .card-group > .card:not(:last-child) .card-img-bottom,
//   .card-group > .card:not(:last-child) .card-footer {
//     border-bottom-right-radius: 0;
//   }
//   .card-group > .card:not(:first-child) {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
//   .card-group > .card:not(:first-child) .card-img-top,
//   .card-group > .card:not(:first-child) .card-header {
//     border-top-left-radius: 0;
//   }
//   .card-group > .card:not(:first-child) .card-img-bottom,
//   .card-group > .card:not(:first-child) .card-footer {
//     border-bottom-left-radius: 0;
//   }
// }


//   .shadow {
//     box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
// }








  @import "~bootstrap/scss/bootstrap";  
